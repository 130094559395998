@import './vars.module.scss';

.root {
  background-color: transparent;
  z-index: 1;
  color: $white;
  width: 24px;
  height: 24px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: solid 3px $medium-gray;
  box-sizing: border-box;
}
.stepperContainer__Onboarding {
  max-width: 393px;
  margin: 0 auto;
}
.onboardingRoot {
  padding: 0 !important;
  position: relative;
  left: -4px;
}
.active {
  background-color: $white;
  border-color: $progress-green;
}

.completed {
  background-color: $progress-green;
  border-color: $progress-green;
}

.connectorActive {
  background-color: $progress-green;
}

.connectorCompleted {
  background-color: $progress-green;
}
.connectorRoot {
  left: calc(-50% + 12px) !important;
  right: calc(50% + 17px) !important;
}
.connectorLine {
  height: 3px;
  border: 0;
  background-color: $medium-gray;
  border-radius: 1px,;
}
.connectorLineThin {
  height: 1px;
  border: 0;
  border-color: $medium-gray;
  background-color: transparent;
  position: relative;
  left: 8px;
}
.alternativeLabel {
  background-color: transparent !important;
  padding: 24px 0 !important;
}
.stepLabel {
  color: $dark-gray !important;
  font-size: 12px !important;
  line-height: 18px !important;
  margin: 16px auto 0 !important;
  max-width: 84px;
}
.stepLabelOnboarding {
  color: $dark-gray !important;
  font-size: 12px !important;
  line-height: 18px !important;
  margin: 16px auto 0 !important;
  max-width: 84px;
  position: relative;
  left: 8px;
  bottom: 8px;
  &:hover {
    cursor: pointer;
  }
}
.stepRoot {
  @media screen and (min-width: 768px) {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}