@import './vars.module.scss';

.welcome__content {
  box-sizing: border-box;
  background-color: $white;
  padding: 26px 42px 31px;
  border-left: solid 6px $green;
  border-right: solid 6px $red;
  position: relative;
  top: -58px;
  max-width: 620px;
  margin: 0 auto;

  &:before {
    display: block;
    content: '';
    background: $orange;
    height: 6px;
    width: calc(100% + 12px);
    position: absolute;
    top: 0;
    left: -6px;
  }

  &:after {
    display: block;
    content: '';
    background: $blue;
    height: 6px;
    width: calc(100% + 12px);
    position: absolute;
    bottom: 0;
    left: -6px;
  }
  @media screen and (max-width: 671px) {
    max-width: 315px;
    padding: 26px 10px 31px;
  }
}
.blueBar {
  height: 98px;
  background-color: $navy;
}
.imagesContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 13px;
  align-items: flex-start;
  grid-area: images;
  @media screen and (min-width: 672px) {
    margin-bottom: 40px;
  }
}
.imageContainer {
  width: 140px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 671px) {
    width: 80px;
  }
}
.imageResume {
  width: 75px;
  @media screen and (max-width: 671px) {
    width: 60px;
  }
}
.imageArrow {
  width: 40px;
  padding-top: 26px;
  @media screen and (max-width: 671px) {
    width: 24px;
    padding-top: 22px;
  }
}
.imageGears {
  width: 71px;
  padding-bottom: 8px;
  @media screen and (max-width: 671px) {
    padding-bottom: 0;
    width: 58px;
  }
}
.imageArchery {
  width: 75px;
  padding-bottom: 13px;
  @media screen and (max-width: 671px) {
    padding-bottom: 0;
    width: 62px;
  }
}
.imageTextContainer {
  text-align: center;
  padding-top: 10px;
  @media screen and (max-width: 671px) {
    display: none;
  }
}
.titleContainer {
  @media screen and (max-width: 671px) {
    padding: 0 20px;
  }
}
.fullTextContainer {
  grid-area: text;
  text-align: center;
  padding: 0 10px;
  @media screen and (min-width: 672px) {
    display: none;
  }
}