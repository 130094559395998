@import './vars.module.scss';

.mainNav__itemsContainer {
  display: flex;
  justify-content: space-around;
  white-space: nowrap;
  overflow: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;  /* Hide scrollbar on IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar on Firefox */
  /* Hide scrollbar on Webkit based browsers */
  &::-webkit-scrollbar {
    display: none;
  }
  @media screen and (min-width: 800px) {
    justify-content: center;
  }
}
.mainNav {
  background-color: $white;
  height: 56px;
  border-bottom: solid 1px $medium-gray;
  overflow: hidden;
  position: relative;

  &__item {
    background-color: $white;
    text-transform: uppercase;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    padding: 0 20px;
    text-decoration: none;

    &:first-child {
      padding-left: 4px;
    }
    &:last-child {
      padding-right: 4px;
    }

    span {
      color: $action-blue;
      font-size: 14px;
      display: flex;
      height: 56px;
      align-items: center;
      box-sizing: border-box;
      background-color: $white;
      transition: all .35s;
      border-top: solid 3px $white;
      border-bottom: solid 3px $white;
    }

    &:hover {
      span {
        color: $black;
        cursor: pointer;
        border-bottom: solid 3px $black;
      }
    }

    &__active {
      span {
        color: $black;
        border-bottom: solid 3px $black;
      }
    }

    @media screen and (max-width: 799px) {
      max-width: 90px;
    }
  }
}
.hidden {
  @media screen and (max-width: 799px) {
    display: none;
  }
}
.breakSpace {
  white-space: initial;
  text-align: center;
}