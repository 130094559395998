@import './vars.module.scss';

.skillCard {
  box-sizing: border-box;
  background-color: $light-gray;
  border: solid 1px $light-gray;
  font-size: 16px;
  line-height: 20px;
  color: $dark-gray;
  font-weight: 700;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  position: relative;
  
  &:active {
    top: 1px;
    transform: scale(.99);
  }
}
.active {
  border: solid 1px $progress-green;
}
@media (hover: hover) {
  .skillCard:hover {
    cursor: pointer;
    border: solid 1px $progress-green;
  }
}