$organization-name: 'Research Improving People\'s Lives';
$logo-header-url: '/images/logo-header.png';
$logo-url: '/images/b2w-logo.png';
$logo-white-url: '/images/b2w-logo-white.png';
$logo-white-width: 55.09px;
$logo-white-height: 31px;
$dlt-logo-url: '/images/ripl-logo.png';
$consent-logo-url: '/images/hican-logo.png';
$dlt-logo-width: 42px;
$dlt-logo-height: 42px;
$consent-logo-width: 280;
$consent-logo-height: 40px;
$logo-footer-url: '/images/logo-footer.png';
$logo-footer-width: 89px;
$logo-footer-height: 33px;
$state-footer-url: '/images/ripl-logo.png';
$state-footer-width: 40px;
$state-footer-height: 37.68px;
$font-family: 'Open Sans', sans-serif;
$primary-color-main: #2E6AD1;
$primary-color-dark: #234C9F;
$navy: #003871;
$accent-color-main: #ffc709;
$secondary-color-main: #FFEF74;
$secondary-color-dark: #FFE412;
$error-color-main: #C90000;
$background-color-default: #efefef;
$background-color-paper: #fff;
$dark-gray: #424242;
$semi-dark-gray: #767676;
$mid-light-gray: #DBDBDB;
$medium-gray: #B7B7B7;
$light-gray: #F5F5F5;
$semi-light-gray: #E2E2E2;
$black: #000;
$white: #FFF;
$red: #EB5152;
$dark-red: #CB3130;
$light-red: #FCE6E6;
$green: #77CC66;
$dark-green: #60A752;
$light-green: #EBF8E8;
$progress-green: #129015;
$dark-progress-green: #0E6E10;
$orange: #EB843B;
$dark-orange: #D07434;
$blue: #8FB9E2;
$dark-blue: #5994CF;
$action-blue: #2E6AD1;
$action-blue-hover: #234C9F;
$tag-skill: lighten($blue, 12%);
$tag-skill-hover: darken($tag-skill, 4%);

$tag-skill-green: #B4E3AA;
$tag-skill-green-hover: darken($tag-skill-green, 5%);
$tag-skill-yellow: lighten(#FFEF74, 10%);
$tag-skill-yellow-hover: #FFEF74;

:export {
  organizationName: $organization-name;
  logoHeaderUrl: $logo-header-url;
  logoFooterUrl: $logo-footer-url;
  fontFamily: $font-family;
  dltLogoUrl: $dlt-logo-url;
  consentLogoUrl: $consent-logo-url;
  stateFooter: $state-footer-url;
  primaryColorMain: $primary-color-main;
  primaryColorDark: $primary-color-dark;
  secondaryColorMain: $secondary-color-main;
  secondaryColorDark: $secondary-color-dark;
  accentColorMain: $accent-color-main;
  errorColorMain: $error-color-main;
  backgroundColorDefault: $background-color-default;
  backgroundColorPaper: $background-color-paper;
  darkGray: $dark-gray;
  semiDarkGray: $semi-dark-gray;
  mediumGray: $medium-gray;
  midLightGray: $mid-light-gray;
  lightGray: $light-gray;
  black: $black;
  white: $white;
  navy: $navy;
  red: $red;
  darkRed: $dark-red;
  lightRed: $light-red;
  green: $green;
  lightGreen: $light-green;
  darkGreen: $dark-green;
  progressGreen: $progress-green;
  orange: $orange;
  darkOrange: $dark-orange;
  blue: $blue;
  darkBlue: $dark-blue;
  actionBlue: $action-blue;
  actionBlueHover: $action-blue-hover;
  tagSkill: $tag-skill;
  tagSkillhover: $tag-skill-hover;

  tagSkillGreen: $tag-skill-green;
  tagSkillGreenHover: $tag-skill-green-hover;
  tagSkillYellow: $tag-skill-yellow;
  tagSkillYellowHover: $tag-skill-yellow-hover;
}