@import './vars.module.scss';

.cardsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  padding-top: 17px;

  @media screen and (max-width: 671px) {
    grid-template-columns: 1fr;
  }
}
.card {
  background-color: $white;
  padding: 16px 14px 31px;
  border-left: solid 6px $green;
  border-right: solid 6px $red;
  position: relative;

  &:before {
    display: block;
    content: '';
    background: $orange;
    height: 6px;
    width: calc(100% + 12px);
    position: absolute;
    top: 0;
    left: -6px;
  }

  &:after {
    display: block;
    content: '';
    background: $blue;
    height: 6px;
    width: calc(100% + 12px);
    position: absolute;
    bottom: 0;
    left: -6px;
  }

  &:hover {
    cursor: pointer;
  }
}
.jobCard {
  background-color: $white;
  padding: 16px 14px 31px;
  border: solid 1px $progress-green;
  position: relative;
}
.jobCardTitle {
  font-weight: 700;
  color: $dark-gray;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 20px;
  display: block;
}
.jobCardSubitle {
  display: block;
  text-align: center;
  color: $semi-dark-gray;
  font-size: 14px;
  padding-top: 1px;
  padding-bottom: 2px;
}
.cardValue {
  display: flex;
  justify-content: center;
  color: $action-blue;
  font-weight: 700;
  font-size: 48px;
}
.cardDescription {
  display: flex;
  justify-content: center;
  color: $dark-gray;
  font-weight: 700;
  max-width: 180px;
  margin: 0 auto;
  text-align: center;
}
.subtitle {
  font-family: 'Source Serif Pro';
  display: flex;
  justify-content: center;
  font-size: 24px;
  margin-bottom: 5px;
}
.textContainer {
  max-width: 600px;
  margin: 0 auto;
}
.text {
  color: $dark-gray;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 22px;
}
.currentLink {
  color: $black;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Open Sans';
  padding-right: 14px;
}
.breadCrumbsLink {
  padding-right: 16px;
}