@import './vars.module.scss';
.header {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: end;
  @media screen and (max-width: 799px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.title {
  font-family: 'Source Serif Pro';
  font-weight: 700;
  color: $black;
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 3px;
  @media screen and (max-width: 799px) {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
  }
}
.subtitle {
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 18px;
  color: $dark-gray;
  display: inline-block;
  @media screen and (max-width: 799px) {
    margin-bottom: 16px;
    text-align: center;
  }
}
.table {
  background-color: $white;
  padding-top: 16px;
  padding-bottom: 13px;
  box-shadow: 0px -9px 11px -4px rgba(0,0,0,0.16);
  z-index: 12;
}
.table__NoShadow {
  box-shadow: none;
}
.mobileTable {
  background-color: $white;
  // box-shadow: 0px -9px 11px -4px rgba(0,0,0,0.16);
  z-index: 12;
  padding: 16px 13px;
}
.tableHeader {
  display: grid;
  height: 180px;
  grid-template-columns: 128px auto min-content;
}
.tableSkillsGroup {
  display: flex;
}
.tableHeader__header {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: stretch;
}
.tableHeaderRow {
  width: 100%;
  display: flex;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 18px;
}
.tableHeaderRow__second {
  border-left: 1px solid $semi-light-gray;
  justify-content: flex-start;
  padding-left: 18px;
}
.tableHeaderCell {
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 12px;
  color: $dark-gray;
}
.headerSkillCell {
  width: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  border-left: 1px solid $semi-light-gray;
}
.headerSkillLabel {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  display: inline-block;
  max-height: 130px;
  font-size: 12px;
  color: $dark-gray;
  line-height: 15px;
}
.matchPercentage {
  font-weight: 700;
  color: $black;
}
.tableRow {
  display: grid;
  min-height: 65px;
  grid-template-columns: 128px auto min-content;
  grid-gap: 0px;
  span, div {
    opacity: 1;
    transition: opacity .4s;
  }
  &:last-child {
    // border-bottom: 1px solid $semi-light-gray;
  }
}
.tableRow__hidden {
  span, div {
    opacity: 0;
  }
}
.mobileRow {
  border: solid 1px $semi-light-gray;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.mobileRow__top {
  display: grid;
  grid-template-columns: 1fr 7fr 1fr;
  grid-gap: 12px;
  padding: 15px 12px;
}
.mobileRow__bottom {
  display: flex;
  justify-content: space-between;
  padding: 0 12px 20px;
}
.mobileCheckContainer {
  display: inline-block;
  padding-right: 4px;
}
.tableNotMatchesRow {
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (min-width: 800px) {
    height: 65px;
    padding-left: 16px;
    border-top: 1px solid $semi-light-gray;
    border-bottom: 1px solid $semi-light-gray;
  }
}
.tableCell {
  border-top: 1px solid $semi-light-gray;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  &:not(:first-child) {
    border-left: 1px solid $semi-light-gray;
  }
}
.titleCell {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 16px;
  justify-content: space-between;
}
.centeredCell {
  display: flex;
  justify-content: center;
  align-items: center;
}
.skillCell {
  width: 60px;
  border-top: 1px solid $semi-light-gray;
  box-sizing: content-box;
  height: 100%;
  border-left: 1px solid $semi-light-gray;
  display: flex;
  justify-content: center;
  align-items: center;
}
.matchCell {
  box-sizing: border-box;
  display: flex;
  padding: 0 34px 0 8px;
  justify-content: space-between;
  align-items: center;
}
.tabs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;
  @media screen and (max-width: 799px) {
    grid-gap: 10px;
  }
}
.tab {
  background-color: $semi-light-gray;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  box-shadow: inset 0px -9px 11px -4px rgba(0,0,0,0.16);
  &:hover {
    cursor: pointer;
  }
  font-family: 'Source Serif Pro';
  font-weight: 700;
  font-size: 16px;
  position: relative;
  @media screen and (max-width: 799px) {
    height: 62px;
  }
}
.activeTab {
  background-color: $white;
  box-shadow: none;
}
.tooltipIcon {
  position: absolute;
  top: 6px;
  right: 6px;
}
.tooltip {
  box-sizing: border-box;
  max-width: 202px !important;
  border-radius: 0 !important;
  font-size: 12px !important;
  background-color: $dark-gray !important;
  padding: 8px 12px !important;
  text-align: center;
}
.tooltipArrow {
  &:before {
    background-color: $dark-gray !important;
  }
}
.selectContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 912px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  & > div {
  }
  span {
    padding: 2px;
  }
}
.industryLabel {
  font-family: 'Source Serif Pro';
  font-size: 16px;
  font-weight: 700;

}
.lastJobLabel {
  font-family: 'Source Serif Pro';
  font-size: 16px;
  font-weight: 400;
}
.candidateId {
  color: rgba(0, 0, 0, 0.603);
  margin-top: .4rem;
  font-family: 'Source Serif Pro';
  font-size: 12px;
  font-weight: 400;
}
.skillsExplanation {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media screen and (min-width: 800px) {
    margin-bottom: 45px;
  }
  @media screen and (max-width: 799px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.skillExplanation__skill {
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 799px) {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 45px;
    }
  }
}
.skillExplanation__text {
  color: $dark-gray;
  font-size: 12px;
  display: block;
  padding-left: 8px;
}
.hourlyRateTitle {
  font-size: 12px;
  font-weight: 700;
  color: $dark-gray;
  line-height: 15px;
  padding-right: 6px;
}
.hourlyRateText {
  font-size: 12px;
  color: $dark-gray;
  line-height: 15px;
}
.noMatchesTitle {
  font-family: 'Source Serif Pro';
  font-size: 16px;
  color: $black;
  font-weight: 700;
}
.noMatchesText {
  font-size: 14px;
  color: $dark-gray;
}
.matchesHeaderContent {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media screen and (max-width: 799px) {
    flex-direction: column;
    align-items: flex-start;
    & > div {
      margin-bottom: 13px;
    }
  }
}
.submitCandidatesBar {
  background-color: $navy;
  position: sticky;
  bottom: 0;
  color: $white;
  font-size: 14px;
  padding: 16px;
  z-index: 13;
  @media screen and (max-width: 799px) {
    padding: 10px 0 20px;
  }
}
.submitCandidatesBarContainer {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 799px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
      text-align: center;
    }
  }
}
.submitCandidatesText {
  @media screen and (max-width: 799px) {
    padding-bottom: 12px;
  }  
}
.plane {
  color: $dark-gray !important;
  font-size: 25px !important;
  position: relative;
  top: 6px;
  left: 2px;
}
.checkboxPlane {
  color: $dark-progress-green;
  font-size: 42px !important;
  position: relative;
  left: 12px;
  top: 10px;
}
.planePrimary {
  color: $white !important;
}
.jobsMatchesWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 330px);
}
.candidateDetailsButtons {
  display: flex;
  justify-content: flex-end;
  button:last-child {
    margin-left: 8px;
  }
}
.candidateDetailsCardTwoColumns {
  background-color: $white;
  padding: 30px 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  @media screen and (max-width: 799px) {
    grid-template-columns: 1fr;
  }
}
.candidateDetailsCard {
  background-color: $white;
  padding: 30px 24px;
}
.candidateSkillsCards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
  @media screen and (max-width: 799px) {
    grid-template-columns: 1fr;
  }
}
.cardCheckImage {
  width: 24px;
  height: 24px;
}
.candidateSkillCard {
  border: solid 1px $semi-light-gray;
  padding: 16px 30px 16px 13px;
  font-size: 16px;
  color: $dark-gray;
  font-weight: 700;
  align-items: center;
  display: flex;
  span {
    padding-left: 11px;
  }
}
.listItem {
  padding-left: 4px;
  padding-right: 16px;
  padding-bottom: 4px;
  @media screen and (min-width: 800px) {
    padding-right: 30px;
  }
  &:before {
    content: '•';
    position: relative;
    left: -4px;
  }
}
.smallScreenPadding {
  @media screen and (max-width: 799px) {
    padding-top: 26px;
  }
}
.checkboxRoot {
  font-size: 14px;
}
.checkboxLabel {
  font-size: 14px !important;
}
.submitBarLink {
  font-weight: 700;
  text-decoration: underline;
  position: relative;
  &:hover {
    cursor: pointer;
    color: $semi-light-gray;
  }
  &:active {
    top: 1px;
  }
  &:not(:last-child) {
    padding-right: 16px;
  }
}
.submitBarLink__Disabled {
  color: $medium-gray;
  &:hover {
    color: $medium-gray;
  }
  &:active {
    top: 0;
  }
}
.descriptionContent {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 16px;
  @media screen and (max-width: 799px) {
    grid-template-columns: 1fr;
  }
}
.descriptionFormCard {
  background-color: $white;
  padding: 24px;
  margin-bottom: 32px;
  &>:global.job-form-section {
    margin-top: 0px;
  }
}
.errorMessage__container {
  margin-bottom: 1rem;
}
.errorMessage {
  font-size: 0.8rem;
  color: $error-color-main;
}
.candidateDetailsLinkContainer {
  display: flex;
  flex-direction: column;
  span {
    color: $dark-gray;
    font-size: 12px;
    white-space: nowrap;
  }
}

.dropdownrow__wrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
  align-items: center;
  border-top: 1px solid #e2e2e2;
  border-left: 1px solid #e2e2e2
}
.requirementsFilters {
  z-index: 10;
  background-color: $light-gray;
  border-radius: 4px;
  padding-top: 4px;
  min-height: 45px;
  padding: 8px 14px;
  div {
    margin-bottom: 0;
  }
  label {
    padding-left: 8px;
  }
}
.sortContainer {
  z-index: 10;
  padding-left: 16px;
  & > div {
    margin-bottom: 0;
  }
}