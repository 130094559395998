@import './vars.module.scss';

.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 18px;
}

.toggleInput {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  box-sizing: border-box;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  -webkit-transition: .3s;
  transition: .3s;
  border-radius: 34px;
  border: solid 2px $semi-dark-gray;
  &:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: -2px;
    bottom: -2px;
    background-color: white;
    -webkit-transition: .3s;
    transition: .3s;
    border-radius: 50%;
    border: solid 2px $semi-dark-gray;
  }
}

.toggleInput:checked + .slider {
  background-color: $dark-progress-green;
  border: solid 2px $dark-progress-green;
}

.toggleInput:checked + .slider:before {
  transform: translateX(10px);
  border: solid 2px $dark-progress-green;
}
