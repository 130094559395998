@import './vars.module.scss';

.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.header {
  height: 64px;
  background-color: $white;
  display: flex;
  align-items: center;
}
.headerContent {
  margin: 0 !important;
  display: flex;
  align-items: center;
  position: relative;
}
.header__blueBar {
  background-color: $navy;
  height: 24px;
}
.header__logo {
  width: $logo-white-width;
  height: $logo-white-height;
}
.header__dltLogo {
  width: $dlt-logo-width;
  height: $dlt-logo-height;
}
.header__consentLogo {
  width: $consent-logo-width;
  height: $consent-logo-height;
  padding-left: 56px;
  @media screen and (max-width: 799px) {
    padding-top: 9px;
    padding-left: 8px;
    width: $consent-logo-width * 0.6;
    height: $consent-logo-height * 0.6;
  }
}
.header__readyHireLogo {
  font-size: 16px;
  font-weight: 700;
  max-width: 80px;
  text-align: center;
  color: $dark-green;
}
.header__readyHireLogo__state {
  color: $dark-gray;
}
.footer {
  background-color: $navy;
  color: $white;
  padding-bottom: 11px;
  position: relative;
}
.footerContent {
  margin: 0 !important;
  padding-top: 20px;
  padding-bottom: 14px;
}
.footerImages {
  height: 40px;
  @media screen and (max-width: 599px) {
    display: flex;
    justify-content: center;
  }
}
.footerText {
  font-size: 14px;
  line-height: 24px;
  max-width: 370px;
  margin: 0 auto;
  @media screen and (max-width: 599px) {
    margin: 0 auto;
    text-align: center;
  }
}
.officialText {
  padding-top: 9px;
  margin: 0;
  @media screen and (max-width: 599px) {
    padding-bottom: 18px;
    text-align: center;
    margin: 0 auto;
  }
}
.footerLink, .footerLink:visited {
  color: $white;
  text-decoration: underline;
}
.footerLink:hover {
  color: $light-gray;
}
.footerLink:active {
  color: $medium-gray;
}
.logoFooter {
  padding-right: 10px;
  height: 33px;
}
.stateFooter {
  height: $state-footer-height;
  width: $state-footer-width;
}
.footer__copy {
  font-size: 12px;
  padding: 5px 0 2px;
  @media screen and (max-width: 599px) {
    text-align: center;
  }
}
.separator {
  width: 100%;
  border-top: solid 1px $medium-gray;
  border-left: none;
  border-right: none;
  border-bottom: none;
  margin-top: 3px;
  margin-bottom: 8px;
}
.separatorBold {
  width: 100%;
  border-top: solid 3px $light-gray;
  border-left: none;
  border-right: none;
  border-bottom: none;
  margin: 0;
}
.colorsBar {
  position: relative;
  display: flex;
}
.colorsBar__color {
  height: 6px;
  width: 25%;
}
.colorsBar__red {
  background-color: $red;
}
.colorsBar__green {
  background-color: $green;
}
.colorsBar__orange {
  background-color: $orange;
}
.colorsBar__blue {
  background-color: $blue;
}
.link {
  color: $action-blue;
  font-weight: 700;
  text-decoration: underline;

  &:visited {
    color: $action-blue
  }

  &:hover, &:active {
    color: $action-blue-hover;
    cursor: pointer;
  }

  &__inactive {
    color: $medium-gray;

    &:hover {
      cursor: default;
      color: $medium-gray;
    }

  }
}
.linkSize {
  font-size: 14px;
}
.headingSize {
  font-size: 28px;
  @media screen and (max-width: 767px) {
    font-size: 24px;
  }
}
.sourceSerif {
  font-family: 'Source Serif Pro';
}

.bold {
  font-weight: 700;
}
.descriptor {
  font-size: 14px;
  line-height: 21px;
  color: $dark-gray;
}
.descriptorBold {
  font-size: 14px;
  line-height: 21px;
  color: $dark-gray;
  font-weight: 700;
}
.formCoachingText {
  font-size: 12px;
  line-height: 18px;
  color: $dark-gray;
}
.infoIcon {
  width: 20px;
  height: 20px;
}
.infoIcon__container {
  padding-left: 9px;
}
.hidden {
  display: none;
}
.searchInputRoot {
  margin-bottom: 0 !important;
  background-color: $white !important;
  transition: all .35s;
  svg {
    fill: $primary-color-main;
    font-size: 26px;
  }
  input {
    padding: 12px 0 13px 0 !important;
    background-color: $white;
    width: 0;
    transition: all .35s;
    &:focus {
      padding: 12px 11px 13px !important;
      width: 200px;
      background-color: $light-gray;
    }
    @media screen and (max-width: 767px) {
      padding: 11px 11px 9px !important;
    }
  }
  @media screen and (max-width: 767px) {
    display: none !important;
  }
}
.inputFocused {
  background-color: $light-gray !important;
  margin-left: 11px;
}
.searchFieldLabel {
  cursor: pointer;
}
.mobileSsearchInputRoot {
  margin-bottom: 0 !important;
  background-color: $white !important;
  transition: all .35s;
  position: absolute !important;
  left: -20px;
  z-index: 3;
  svg {
    fill: $primary-color-main;
    font-size: 26px;
  }
  input {
    padding: 0 !important;
    background-color: $white;
    /*width: 0;*/
    transition: all .35s;
    position: absolute;

    &:focus {
      padding: 12px 32px 13px !important;
      width: calc(100vw - 94px);
      background-color: $light-gray;
      @media screen and (max-width: 599px) {
        width: calc(100vw - 79px);
      }
    }
  }
  @media screen and (min-width: 768px) {
    display: none !important;
  }
}
.browserBanner {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 12px 16px;
  background-color: $secondary-color-main;
}
.relative {
  position: relative;
}
.centered {
  text-align: center;
}
.colSpan2 {
  @media screen and (min-width: 800px) {
    grid-column: 1 / span 2;
  }
}
.marginNone {
  margin: 0 !important;
}
.noWrap {
  white-space: nowrap;
}
.linkPadding {
  padding-right: 14px;
}
.content {
  padding: 30px 0;
}
.envBanner {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 12px 16px;
  background-color: $red;
  color: $white;
}
