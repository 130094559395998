@import './vars.module.scss';

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalPaper {
  box-sizing: border-box;
  position: relative;
  background-color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 40px 45px;
  max-width: 90vw;
  @media screen and (max-width: 767px) {
    padding: 30px 20px 45px;
  }
}
.modalCloseContainer {
  position: absolute !important;
  right: 12px;
  top: 12px;
  color: $primary-color-main !important;
  border-radius: 4px !important;
  padding: 5px !important;
}
.modalClose {
  font-size: 18px !important;
  position: relative;
  left: 2px;
  top: 2px;
}