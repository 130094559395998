@import './vars.module.scss';

.formControlRoot {
  display: inline-block !important;
  width: auto !important;
  margin-bottom: 0 !important;
}
.formControlLabelRoot {
  display: inline-block;
  margin-right: 6px !important;
  @media screen and (max-width: 767px) {
    margin-right: 3px !important;
  }
}
.formControlLabeLabel {
  font-size: 0.8rem !important;
  color: $dark-gray;
  @media screen and (max-width: 767px) {
    font-size: 0.68rem !important;
  }
}
.tosContainer {
  margin-bottom: 1.3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.tosLink {
  font-size: 0.8rem !important;
  position: relative;
  top: 1px;
  color: $action-blue;
  cursor: pointer;
  &:hover {
    color: $action-blue-hover;
    text-decoration: underline;
  }
  @media screen and (max-width: 767px) {
    font-size: 0.68rem !important;
    top: 0;
  }
}
.imageMagnifier {
  width: 80.27px;
  @media screen and (max-width: 671px) {
    width: 53.94px;
  }
}
.imageArrow {
  width: 40px;
  padding-top: 26px;
  @media screen and (max-width: 671px) {
    width: 24px;
    padding-top: 22px;
  }
}
.imagePlane {
  width: 77.29px;
  @media screen and (max-width: 671px) {
    width: 61.54px;
  }
}
.imageHandshake {
  width: 89.87px;
  @media screen and (max-width: 671px) {
    width: 65.79px;
  }
}
.imagesContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 13px;
  align-items: flex-start;
  grid-area: images;
  @media screen and (min-width: 672px) {
    margin-bottom: 40px;
  }
}
.industryImagesContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 13px;
  align-items: flex-start;
  grid-area: images;
}
.resumeStepContainer {
  display: grid;
  padding: 40px 32px;
  grid-template-areas: "images"
                       "drop"
                       "link";
  @media screen and (max-width: 671px) {
    padding: 40px 16px 40px;
    grid-template-areas: "drop"
                         "link"
                         "images"
                         "text";
  }
}
.industryStepContainer {
  display: grid;
  padding: 40px 32px;
  grid-template-areas: "images"
                       "link"
                       "cards";
  @media screen and (max-width: 671px) {
    padding: 40px 16px 40px;
    grid-template-areas: "cards"
                         "link"
                         "images"
                         "text";
  }
}
.tosHelperTextRoot {
  left: 0;
  top: 27px !important;
  right: initial !important;
  margin-left: 26px !important;
  color: $error-color-main !important;
}
.resumeUploadContainer {
  max-width: 325px;
  margin: 0 auto;
  grid-area: drop;
}
.cardsContainer {
  grid-area: cards;
  @media screen and (min-width: 672px) {
    padding-top: 26px;
  }
}
.goalsContainer {
  padding: 10px 30px 38px;
}
.imageContainer {
  width: 140px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: 671px) {
    width: 80px;
  }
}
.imageTextContainer {
  text-align: center;
  padding-top: 10px;
  @media screen and (max-width: 671px) {
    display: none;
  }
}
.resumeFullTextContainer {
  grid-area: text;
  text-align: center;
  @media screen and (min-width: 672px) {
    display: none;
  }
}
.resumeLinkContainer {
  grid-area: link;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 34px 0 40px;
  @media screen and (min-width: 672px) {
    padding: 34px 0 4px;
    flex-direction: row;
    justify-content: center;
  }
}
.industryLinkContainer {
  grid-area: link;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 34px 0 40px;
  @media screen and (min-width: 672px) {
    padding: 11px 0 4px;
    flex-direction: row;
    justify-content: center;
  }
}
.goalsLinkContainer {
  grid-area: link;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.enterInfoLink {
  text-align: center;
  padding-top: 2px;
  @media screen and (min-width: 672px) {
    padding-left: 8px;
    padding-top: 0;
  }
}
.notSureLink {
  padding-top: 37px;
}
.goalsTitle {
  max-width: 472px;
  margin: 0 auto 22px;
}
.formContainer {
  max-width: 422px;
  margin: 0 auto;
}
.linkSize {
  font-size: 14px;
}
.dropZone {
  padding: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 250px;
  width: 100%;
  box-sizing: border-box;
  border: dashed 1px $medium-gray;
  &:hover {
    cursor: pointer;
  }

  &__inactive {

    & p,
    & h2 {
      color: $medium-gray
    }

    & img {
      opacity: .5;
    }

    &:hover {
      cursor: default;
    }

  }
}

.uploadImage {
  display: block;
  margin: 0 auto;
  width: 30px;
}
.list {
  padding: 0 !important;
  li {
    padding-top: 0 !important;
  }
  div {
    margin-top: 0 !important;
  }
}
.cardsContainer {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
  padding-top: 19px;
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
.addSkillBox {
  border: dashed 1px $medium-gray;
  background-color: $white;
  padding: 18px 0;
  display: flex;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
}
.addPlus {
  color: $action-blue;
  font-size: 24px !important;
  position: relative;
  right: 2px;
}
.addSkillLink {
  position: relative;
  top: 2px;
}
.industryLink {
  position: relative;
  top: -8px;
  display: block;
  margin-bottom: 16px;
}
.salaryWidget {
  box-sizing: border-box;
  background-color: $white;
  padding: 20px 4px;
  border-left: solid 6px $green;
  border-right: solid 6px $red;
  position: relative;
  max-width: 620px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  &:before {
    display: block;
    content: '';
    background: $orange;
    height: 6px;
    width: calc(100% + 12px);
    position: absolute;
    top: 0;
    left: -6px;
  }

  &:after {
    display: block;
    content: '';
    background: $blue;
    height: 6px;
    width: calc(100% + 12px);
    position: absolute;
    bottom: 0;
    left: -6px;
  }

  @media screen and (min-width: 672px) {
    position: absolute;
    top: 39px;
    right: -115px;
    width: 110px;
  }
  @media screen and (min-width: 768px) {
    right: -94px;
  }
}
.salaryWidget__positionStatic {
  box-sizing: border-box;
  background-color: $white;
  padding: 20px 4px;
  border-left: solid 6px $green;
  border-right: solid 6px $red;
  max-width: 620px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  &:before {
    display: block;
    content: '';
    background: $orange;
    height: 6px;
    width: calc(100% + 12px);
    position: absolute;
    top: 0;
    left: -6px;
  }

  &:after {
    display: block;
    content: '';
    background: $blue;
    height: 6px;
    width: calc(100% + 12px);
    position: absolute;
    bottom: 0;
    left: -6px;
  }
}
.onboardingHeaderThin {
  padding: 33px 16px;
  @media screen and (min-width: 671px) {
    padding: 33px 110px;
  }
}